import "./styles.css";
import { Menu } from "react-float-menu";
import "react-float-menu/dist/react-float-menu.css";

export default function App() {
  return (
    <div className="App">
      <Menu
        dimension={40}
        pin
        items={[
          { name: "File" },
          {
            children: [
              { children: [{ name: "Cut 1" }, { name: "Cut 2" }], name: "Cut" },
              { name: "Select All" }
            ],
            name: "Edit"
          },
          { name: "Add" },
          {
            children: [
              { name: "Copy from clipboard" },
              { name: "Copy selection" }
            ],

            name: "Copy"
          },
          { name: "Save" },
          { name: "Logout" }
        ]}
        shape="square"
        startPosition="top left"
        width={250}
        onSelect={(val) => console.log(val)}
      >
        <span role="img" aria-label="star">
          ⭐
        </span>
      </Menu>
    </div>
  );
}
