import './mui.min.css';
import React from 'react';
import ReactDOM from 'react-dom'; // For react 17
// For react 18: import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App3 from './App3';
import App2 from './App2';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

import { FronteggProvider } from '@frontegg/react';
import { StrictMode } from "react";






ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('rootb'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
const contextOptions = {
  baseUrl: 'https://app-4oar2pcrodsl.frontegg.com',
  clientId: '9f11e16a-6c7a-453a-8d8d-c0aaaee290ab'
};

// For react 18: 
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
ReactDOM.render(
    <FronteggProvider contextOptions={contextOptions} hostedLoginBox={true}>
        <App2 />
    </FronteggProvider>,
    document.getElementById('root')
);
const rootElement = document.getElementById("root3");
const root = ReactDOMClient.createRoot(rootElement);

root.render(
  <StrictMode>
    <App3 />
  </StrictMode>
);